.HS__scroll-arrow {
  width: 10rem;
  height: 7rem;
  grid-row: 8/9;
  grid-column: 6/7;
  position: relative;

  /* margin-left: 2.8rem; */
}

.arrows {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 6rem;
  /* margin-left: -30px; */
  /* bottom: 5rem; */
}

.arrows path {
  stroke: var(--primary);
  fill: transparent;
  stroke-width: 0.7rem;
  animation: arrow 1s infinite;
  -webkit-animation: arrow 2.5s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

@media screen and (max-width: 1400px) {
  .HS__scroll-arrow {
    display: none;
  }
}
