:root {
  --secondary-color: #1e0e62;
  --primary: #16c48a;
  --text: hsla(241, 47%, 15%, 0.5);
}

#create_and_share {
  width: 95vw;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 15rem;
}

.CAS--main-img {
  clip-path: inset(0 0 0 25%);
  width: 35vw;
  min-width: 60rem;

  margin-left: -20rem;
  /* margin-right: 4rem; */
  grid-column: 1/4;
  grid-row: 1/4;
}

.CAS-text--p1,
.CAS-text--p2 {
  font-size: calc(1.8rem + 0.5vw);
  color: var(--text);
  line-height: 1.5;
  font-weight: 300;
  /* margin-left: 1.5rem; */
}

.CAS-text--p1 {
  grid-column: 5/11;
  grid-row: 1/2;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* align-self: flex-end; */
}

.CAS-text--p2 {
  grid-column: 5/11;
  grid-row: 2/4;

  margin-right: 3rem;
}

.CAS-learn-more--btn {
  color: var(--subtitle);

  font-size: 2rem;
  font-weight: 500;
  height: 5rem;
  background-color: transparent;
  border: none;
  width: fit-content;
  margin-bottom: -1rem;
  text-decoration: underline;

  grid-column: 9/12;
  /* grid-column: 5/7; */
  grid-row: 2/3;
  /* justify-self: start; */
  align-self: flex-end;
  padding-top: 2rem;
  margin-left: 5rem;
}

.CAS--hover-underline {
  display: inline-block;
  position: relative;
}

.CAS--hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  top: 100%;
  left: 0;
  background-color: var(--primary-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.CAS--hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.CAS-order-trowit--btn {
  color: var(--secondary-color);
  font-size: 2rem;
  background: #fff;
  border: 2px solid var(--primary);
  border-radius: 10px;
  transition: all 0.3s ease-out;
  height: fit-content;
  width: fit-content;
  margin-bottom: -1rem;
  padding: 0.5rem 2rem;
  grid-column: 5/9;
  grid-row: 2/3;
  justify-self: center;
  align-self: flex-end;
}

.CAS-order-trowit--btn:hover {
  /* color: var(--primary); */
  background: rgba(37, 218, 197, 0.4);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  /* border: 2px dotted #fff; */
  transition: all 0.3s ease-out;
}

@media screen and (min-width: 1135px) and (max-width: 1401px) {
  .CAS-text--p1,
  .CAS-text--p2 {
    font-size: 3.5rem;
    grid-column: 5/12;
  }

  /* .CAS-text--p2 {
    margin-bottom: -3rem;
  } */

  .CAS-learn-more--btn,
  .CAS-order-trowit--btn {
    align-self: center;
    margin-top: 15rem;
    font-size: 3rem;
    justify-self: center;
  }
  .CAS-order-trowit--btn {
    grid-column: 5/12;
    grid-row: 2/3;
    justify-self: start;
    align-self: center;
    height: fit-content;
  }

  .CAS-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 12/14;
    margin-left: -3rem;
    /* margin-top: 10rem; */
  }

  .CAS--main-img {
    margin-left: -20rem;
    min-width: 80rem;
    /* margin-right: 10rem; */
  }
}

@media screen and (min-width: 891px) and (max-width: 1135px) {
  .CAS-text--p1,
  .CAS-text--p2 {
    font-size: 3rem;
    grid-column: 4/13;
    margin-left: 3rem;
  }

  .CAS-order-trowit--btn {
    /* margin-left: 3rem; */
    grid-column: 4/11;
    font-size: 2.8rem;
    justify-self: center;
    /* justify-self: end; */
  }

  .CAS-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 11/14;
    font-size: 3rem;
    /* margin-left: -3rem; */
    /* margin-top: 10rem; */
  }

  .CAS--main-img {
    /* margin-left: -20rem; */
    min-width: 70rem;
    /* margin-right: 10rem; */
  }
}

@media screen and (min-width: 648px) and (max-width: 891px) {
  .CAS-text--p1,
  .CAS-text--p2 {
    font-size: 2.8rem;
    grid-column: 5/14;
  }

  .CAS-order-trowit--btn {
    /* margin-left: 10rem; */
    grid-column: 1/10;
    font-size: 2.5rem;
  }

  .CAS-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 10/14;
    font-size: 2.8rem;
    margin: 0;
    margin-left: -3rem;
  }

  .CAS--main-img {
    min-width: 60rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 649px) {
  #create_and_share {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }

  .CAS--main-img {
    display: none;
  }

  .CAS-text--p1,
  .CAS-text--p2 {
    font-size: 2.3rem;
    width: 90vw;
    text-align: center;
    margin: 0;
  }

  .CAS-text--p2 {
    margin-top: 3rem;
  }

  .CAS-learn-more--btn {
    margin-inline: auto;
    padding: 1rem;
  }
  .CAS-order-trowit--btn {
    margin-inline: auto;
    font-size: 2rem;
    height: fit-content;
    width: fit-content;
  }
}
