:root {
  --header-height: 2rem;
  --nav-width: 6rem;
  --nav-icon-size: 3rem;
  --first-color: #16c48a;
  --first-color-alt: #ffff;
  --white-color: #fff;
  font-family: "Nunito Sans";
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.sidebar,
.sidebar * {
  transition-duration: 120ms;
  transition-property: all;
  transition-timing-function: ease;
}

.sidebar {
  position: fixed;
  z-index: 999;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: var(--nav-width);
  background-color: var(--first-color);
}

.sidebar:hover .nav-link-name {
  width: 24rem;
  padding-inline: 1rem 2rem;
}

.nav {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
}

.nav-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--nav-width);
  color: var(--white-color);
  cursor: pointer;
  width: 100%;
}

.nav-link-icon {
  width: var(--nav-icon-size);
  height: var(--nav-icon-size);
  margin-right: calc((var(--nav-width) - var(--nav-icon-size)) / 2);
  /* margin: calc((var(--nav-width) - var(--nav-icon-size)) / 2); */
}
.nav-link-logo {
  height: var(--nav-icon-size);
  margin-right: calc((var(--nav-width) - var(--nav-icon-size)) / 2);
}

.nav-link-name {
  width: 0rem;
  font-size: 2rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

#logo-close {
  display: flex;
  width: 100%;
  align-items: center;
}

@media screen and (min-width: 0) and (max-width: 480px) {
  .sidebar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* padding: 1rem 0 0 0; */
  }

  .nav-link-name {
    width: unset;
    padding-inline: 1rem 2rem;
  }

  .flex-spacer {
    flex-grow: 1;
  }
}
