#view-contact-content {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  min-height: 100%; 
}

#view-contact-content > .contact-card-view {
  flex: 2;
}

#view-contact-content > .edit-contact-notes {
  flex: 3;
}

#view-contact-content > .contact-more-info {
  flex: 2;
}


.nav-btn {
  margin-left: 30%;
  margin-right: 20%;
}

.search-area {
  display: flex;
  justify-content: flex-start;
}

.arrow {
  font-size: 3rem;
  margin-top: 3%;
}

#contact-card-left {
  /* background-color: azure; */
  width: 20rem;
  padding-inline-end: 10rem;
}

.img {
  width: 30rem;
}

.card_right {
  width: 30rem;
}

#contact-card-form {
  width: 100%;
  max-width: 36rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-card-form > .contact-card-form__input-title {
  font-family: "Raleway", "sans-serif";
  font-weight: 500;
  font-size: 1.7rem;
  letter-spacing: 0.08rem;
  width: 100%;
}

#contact-card-form > input {
  border: none;
  width: 100%;
  padding-block: 0.6rem;
  padding-inline: 1.2rem;
}
#contact-card-form > input::placeholder {
  color: #a8a8a8;
  font-family: "Raleway";
  font-weight: 10;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.contact-card-form-divider {
  margin: 0;
  width: 100%;
  border-top: rgba(0, 0, 0, 0.185);
  border-inline: none;
}

#contact-card-form > input {
  width: 100%;
}

.contact-card-form-divider {
  margin: 0;
  width: 90%;
  border-top: rgba(0, 0, 0, 0.185);
  border-inline: none;
}

.search-area > a {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  margin-inline-end: 1.5rem;
}

.search-area > a > svg {
  margin-bottom: 2%;
  height: 100%;
  width: 100%;
}

#contact-card {
  height: 100vh;
  font-size: 1rem;
}

#contact-card > h1 {
  font-size: 2.5rem;
  color: black;
}

#contact-card-form > form > span.search-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 56rem;
  margin-bottom: 5rem;
}

#contact-card-form > form > span.search-area > a {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  margin-inline-end: 1.5rem;
}

#contact-card-form > form > span.search-area > a > svg {
  height: 100%;
  width: 100%;
}

#contact-card-form > form > span.search-area > input {
  height: 4rem;
  font-size: 1.6rem;
  width: 48rem;
  padding-inline: 1.6rem;
  min-width: 24rem;
  display: inline-block;
  border: none;
  border-radius: 2rem;
  background-color: white;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.2);
}

#contact-card-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: stretch;
}

#contact-contact-btn-save {
  padding: 0.5rem 2rem;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-color: transparent;
  background-color: var(--primary);
  color: #fff;
  font-size: 2.5rem;
  text-transform: uppercase;
  justify-self: flex-end;
  cursor: pointer;
  margin-top: 2%;
}

#contact-card-right {
  width: 60rem;
  border-radius: 2rem;
  outline-width: 0.2rem;
}

#contact-card-right > * {
  filter: none;
}

#contact-card-right > h1 {
  font-size: 1.8rem;
  padding: 1.5rem 3rem;
}

#contact-card-right-grid {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: dense;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  align-content: start;
}
