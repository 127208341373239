:root {
  --secondary-color: #1e0e62;
  --primary: #16c48a;
  --text: hsla(241, 47%, 15%, 0.5);
}

#manage_contact {
  width: 95vw;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
}

.MC--main-img {
  clip-path: inset(0 20% 0 0);
  width: 40vw;

  margin-right: -30rem;
  grid-column: 11/15;
  grid-row: 1/4;
}

.MC-text--p1,
.MC-text--p2 {
  font-size: calc(1.8rem + 0.5vw);
  color: var(--text);
  line-height: 1.7;
}

.MC-text--p1 {
  grid-column: 2/9;
  grid-row: 1/2;
  align-self: flex-end;
  margin-right: 5rem;
}

.MC-text--p2 {
  grid-column: 2/9;
  grid-row: 2/3;
  margin-right: 10rem;
  align-self: flex-start;
}

.MC-learn-more--btn {
  color: var(--subtitle);

  font-size: 2rem;
  font-weight: 500;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: none;
  width: fit-content;
  text-decoration: underline;

  grid-column: 2/4;
  grid-row: 3/4;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0;
  margin-top: 1rem;
  margin-right: 5rem;
}

.MC--hover-underline {
  display: inline-block;
  position: relative;
}

.MC--hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  top: 65%;
  left: 0;
  background-color: var(--primary-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.MC--hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.MC-order-trowit--btn {
  color: var(--secondary-color);
  font-size: 2rem;
  background: #fff;
  border: 2px solid var(--primary);
  border-radius: 10px;
  transition: all 0.3s ease-out;
  height: 3.5rem;
  margin-top: 1rem;
  width: fit-content;
  padding: 1rem;

  grid-column: 3/8;
  grid-row: 3/4;
  align-self: flex-start;
  justify-self: start;
  margin-left: 3rem;
  margin-top: -1rem;
  width: fit-content;
  height: fit-content;
}

.MC-order-trowit--btn:hover {
  /* color: var(--primary); */
  background: rgba(37, 218, 197, 0.4);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  /* border: 2px dotted #fff; */
  transition: all 0.3s ease-out;
}

@media screen and (min-width: 1135px) and (max-width: 1401px) {
  #manage_contact {
    margin-left: 3rem;
  }

  .MC-text--p1,
  .MC-text--p2 {
    font-size: 3rem;
    grid-column: 1/10;
  }

  .MC-text--p1 {
    grid-row: 1/2;
    margin: 0;
  }

  .MC-text--p2 {
    grid-row: 2/3;
    margin: 0;
  }

  .MC-order-trowit--btn {
    /* margin-left: 10rem; */
    grid-column: 1/7;
    font-size: 2.5rem;
    grid-row: 3/5;
    align-self: center;
    height: fit-content;
    font-size: 3rem;
  }

  .MC-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 7/9;
    font-size: 2.8rem;
    /* margin-left: -3rem; */
    margin-top: 3rem;
    grid-row: 3/4;
    align-self: center;
    justify-self: flex-start;
    height: fit-content;
  }

  .MC--main-img {
    min-width: 60rem;
  }
}

@media screen and (min-width: 891px) and (max-width: 1135px) {
  #manage_contact {
    margin-left: 3rem;
  }

  .MC-text--p1,
  .MC-text--p2 {
    font-size: 3rem;
    grid-column: 1/11;
  }

  .MC-text--p1 {
    grid-row: 1/2;
    margin: 0;
  }

  .MC-text--p2 {
    grid-row: 2/3;
    margin: 0;
  }

  .MC-order-trowit--btn {
    /* margin-left: 10rem; */
    grid-column: 1/7;
    font-size: 2.5rem;
    grid-row: 3/5;
    align-self: center;
    height: fit-content;
    font-size: 3rem;
  }

  .MC-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 7/9;
    font-size: 2.8rem;
    /* margin-left: -3rem; */
    margin-top: 3rem;
    grid-row: 3/4;
    align-self: center;
    justify-self: flex-start;
    height: fit-content;
  }

  .MC--main-img {
    min-width: 60rem;
  }
}

@media screen and (min-width: 648px) and (max-width: 891px) {
  #manage_contact {
    margin-left: 3rem;
  }

  .MC-text--p1,
  .MC-text--p2 {
    font-size: 2.8rem;
    grid-column: 1/11;
  }

  .MC-text--p1 {
    grid-row: 1/2;
    margin: 0;
  }

  .MC-text--p2 {
    grid-row: 2/3;
    margin: 0;
  }

  .MC-order-trowit--btn {
    /* margin-left: 10rem; */
    grid-column: 1/10;
    font-size: 2.5rem;
    grid-row: 3/5;
    align-self: center;
    height: fit-content;
  }

  .MC-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 10/14;
    font-size: 2.8rem;
    /* margin-left: -3rem; */
    margin-top: 3rem;
    grid-row: 3/4;
    align-self: center;
    height: fit-content;
  }

  .MC--main-img {
    min-width: 60rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 649px) {
  #manage_contact {
    display: flex;
    flex-direction: column;
    width: 100vw;

    margin-top: 5rem;
  }

  .MC--main-img {
    display: none;
  }

  .MC-text--p1,
  .MC-text--p2 {
    font-size: 2.3rem;
    width: 90vw;
    text-align: center;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .MC-text--p2 {
    margin-top: 3rem;
  }

  .MC-learn-more--btn {
    margin-inline: auto;
    padding: 1rem;
    font-size: 2rem;
  }
  .MC-order-trowit--btn {
    /* margin-inline: auto; */
    font-size: 2rem;
    height: fit-content;
    margin-inline: auto;
    padding-inline: 3rem;
  }
}
