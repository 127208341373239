@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap");

:root {
  --primary: #16c48a;
  --primary-dark: #1eae9e;
  --subtitle: #1e0e62;
}

#hero-section {
  /* width: inherit; */
  font-family: "Raleway", sans-serif;
  font-style: normal;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(7, 1fr);
  margin-bottom: 10rem;

  /* position: relative; */
  /* gap: rem; */
}

.heading-primary-main {
  color: hsla(252, 76%, 22%, 1);
  /* font-size: 7rem; */
  /* font-size: 4vw; */
  font-size: calc(3rem + 2vw);
  letter-spacing: 0.1rem;
  font-weight: 700;
  line-height: 1.5;

  grid-column: 2/8;
  grid-row: 2/4;
  align-self: start;
  /* padding-right: 3rem; */
}
.heading-primary-sub {
  color: hsla(241, 47%, 15%, 0.4);
  font-size: calc(1.5rem + 0.5vw);
  font-weight: 500;
  line-height: 2;

  grid-column: 2/7;
  grid-row: 4/6;
  align-self: start;
}

.C2A--btn {
  color: #fff;
  font-size: 2.8rem;
  font-weight: 500;
  width: 40rem;
  height: 7rem;
  background-color: var(--primary);
  border-radius: 40px;
  border: none;

  grid-column: 2/4;
  grid-row: 6/7;
  align-self: flex-start;
  margin-top: -5rem;
}

.C2A--btn:hover {
  color: var(--primary);
  background: #fff;
  border: 0.1rem solid var(--primary);
  transition: all 0.3s ease-out;
}

.more--btn {
  color: var(--subtitle);
  font-size: 2.1rem;
  font-weight: 500;
  width: 20rem;
  height: 5rem;
  background-color: transparent;
  border: none;
  grid-column: 2/4;
  grid-row: 6/7;
  justify-self: center;
  align-self: end;
  margin-right: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}

/* hover effect more btn */

.hover-underline {
  display: inline-block;
  position: relative;
}

.hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2rem;
  bottom: 0;
  left: 0;
  background-color: var(--primary-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hero-img {
  grid-column: 9/14;
  grid-row: 1/13;
  width: 130%;
  height: auto;

  clip-path: inset(0 8% 0 0);
}

/* Tablet  */
@media screen and (min-width: 888px) and (max-width: 1400px) {
  #hero-section > * {
    font-size: 4rem;
  }

  #hero-section {
    margin-bottom: 3rem;
  }

  .C2A--btn {
    font-size: 3.5rem;
    width: 50rem;
    height: 10rem;
  }

  .more--btn {
    font-size: 3rem;
    padding-top: 3rem;
  }

  .hero-img {
    max-width: 150%;
    min-width: 40rem;
  }
}

/* tablet    */
@media screen and (min-width: 650px) and (max-width: 887px) {
  .C2A--btn {
    font-size: 3rem;
    width: 40rem;
    height: 8rem;
    grid-row: 7/10;
  }

  #hero-section {
    margin-left: 3rem;
  }
  .more--btn {
    font-size: 2.8rem;
    padding-top: 2rem;
    grid-row: 7/8;
  }

  .heading-primary-main {
    grid-column: 2/10;
    padding-right: 1rem;
    margin-top: -4rem;
    font-size: 5rem;
  }

  .heading-primary-sub {
    font-size: 2.3rem;
    grid-column: 2/7;
    padding: 0;
    margin-top: 1rem;
  }

  .hero-img {
    max-width: 150%;
    min-width: 40rem;
  }
}

/* mobile */

@media screen and (min-width: 0px) and (max-width: 649px) {
  #hero-section {
    margin-inline: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    height: 100vh;

    gap: 5rem;
    /* justify-content: center;
    align-items: center; */
  }

  .heading-primary-main {
    font-size: 6rem;
    line-height: 7rem;
    width: 70vw;
  }

  .heading-primary-sub {
    font-size: 2.3rem;
    line-height: 2;
    width: 70vw;
  }

  .C2A--btn {
    font-size: 2.2rem;
    width: 30rem;
    height: 5.5rem;
    margin: 0;

    align-self: center;
    margin-bottom: -4rem;
  }
  .more--btn {
    font-size: 2.3rem;
    /* margin: 0; */
    height: fit-content;
    text-align: center;
    margin-inline: auto;

    /* background-color: #1eae9e; */
  }

  .hero-img {
    display: none;
  }
}
/* 
@media screen and (max-width: 600px) {
} */
