#order-card {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: hsla(0, 0%, 20%, 1);
  width: 100%;
}

.OC__main-container {
  min-height: 100%;
  width: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/* .OC__sub-container2__sub-title-and-preview-cards {
  background-color: navajowhite;
} */
.OC__sub-container2__sub-title {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 1.5rem;
}
.OC__sub-container2__preview-cards {
  display: flex;
  gap: 3rem;
}
.OC__sub-container2__single-card {
  /* background: url("../../images/Order_physical_card_page/card_preview@2x.png"),
    white; */
  /* border: 0.4rem solid var(--primary); */
  width: 100px;
  height: 100px;
  border: solid 5px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.8rem;
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  min-height: 15rem;
  min-width: 12rem;
}

.OC__sub-container2__single-card img {
  width: 100%;
  height: 80%;
}

.OC__sub-container2__preview-card-category {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.OC__sub-container3__price-table-and-buttons {
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.OC__sub-container3__price-cards {
  display: flex;
  justify-content: space-between;
  margin-right: 15rem;
}

.OC__sub-container3__price-frame {
  width: 27rem;
  height: 37rem;
  padding-top: 2rem;
  background-color: #fff;
  border-radius: 1rem;
  border: 0.3rem solid var(--primary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.OC__sub-container3__price-frame.business {
  border: 0.3rem solid hsla(251, 75%, 22%, 1);
  justify-content: space-between;
}

.OC__sub-container3___header {
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

/* .OC__sub-container3___header.business {
} */

.OC__sub-container3__price-frame__cta {
  font-size: 1.7rem;
  font-style: italic;
  text-align: center;
  width: 80%;
  margin-top: -1rem;
  line-height: 22px;
}

.OC__sub-container3__price-frame__ctas.business {
  width: 100%;
  height: 70%;
}

.OC__sub-container3__price-frame__cta.business {
  width: 80%;
  margin-inline: auto;
  margin-bottom: 4rem;
}

/* .OC__sub-container3__price-frame__benefits {
  height: 50%; 
   gap: 2rem;
} */

.OC__sub-container3__price-frame__single-benefit {
  font-weight: 200;
  font-size: 1.4rem;
  margin-top: 0.5rem;
}
.OC__sub-container4__price-and-fees {
  background-color: greenyellow;
}
.OC__sub-container3__price {
  color: var(--primary);
  font-size: 5rem;
  font-weight: 900;
  text-align: center;
  padding: -5px;
}
.OC__sub-container3__discountprice {
  color: "#34455E";
  font-size: 4rem;
  text-decoration-line: line-through;
  text-decoration-thickness: 0.4rem;
  text-decoration-color: red;
  font-weight: 900;
  text-align: center;
  padding: -5px;
}
.OC__sub-container3__fee {
  font-weight: 400;
  font-size: 1.2rem;
  color: #9b9b9ba1;
  padding-bottom: 3rem;
}

.OC__sub-container3__button {
  padding: 0.2rem 6rem;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-color: transparent;
  background-color: var(--primary);
  color: #fff;
  font-size: 2.5rem;
  text-transform: uppercase;
  cursor: pointer;
}

.OC__sub-container3__button.color-grey {
  background-color: #898989;
}

.OC__sub-container3__button.business {
  /* width: fit-content; */
  padding: 0.5rem 2rem;
  font-size: 2.2rem;
  background-color: hsla(251, 75%, 22%, 1);
}
