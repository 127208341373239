:root {
  --secondary-color: #1e0e62;
  --primary: #16c48a;
  --text: hsla(241, 47%, 15%, 0.5);
}

#impress_your_peers {
  width: 95vw;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
  /* margin-top: 5rem; */
}

.IYP--main-img {
  clip-path: inset(0 0 0 25%);
  width: 35vw;

  margin-left: -20rem;
  grid-column: 1/5;
  grid-row: 1/4;
}

.IYP-text--p1,
.IYP-text--p2 {
  font-size: calc(1.8rem + 0.5vw);
  color: var(--text);
  line-height: 1.7;
}

.IYP-text--p1 {
  grid-column: 5/10;
  grid-row: 1/2;
  align-self: flex-end;
}

.IYP-text--p2 {
  grid-column: 5/11;
  grid-row: 2/3;
  align-self: flex-start;
  margin-right: 3rem;
}

.IYP-learn-more--btn {
  color: var(--subtitle);

  font-size: 2rem;
  font-weight: 500;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: none;
  width: fit-content;
  margin-bottom: -1rem;
  text-decoration: underline;

  grid-column: 5/7;
  grid-row: 3/3;
  justify-self: start;
  align-self: center;
}

.IYP--hover-underline {
  display: inline-block;
  position: relative;
}

.IYP--hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  top: 65%;
  left: 0;
  background-color: var(--primary-dark);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.IYP--hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.IYP-order-trowit--btn {
  color: var(--secondary-color);
  font-size: 2rem;
  background: #fff;
  border: 2px solid var(--primary);
  border-radius: 10px;
  transition: all 0.3s ease-out;
  /* margin-top: 1rem; */
  width: fit-content;
  padding: 1.2rem;

  grid-column: 6/10;
  grid-row: 3/3;
  align-self: center;
}

.IYP-order-trowit--btn:hover {
  /* color: var(--primary); */
  background: rgba(37, 218, 197, 0.4);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  /* border: 2px dotted #fff; */
  transition: all 0.3s ease-out;
}
@media screen and (min-width: 1135px) and (max-width: 1401px) {
  .IYP-text--p1,
  .IYP-text--p2 {
    font-size: 3.5rem;
    grid-column: 5/13;
  }

  .IYP-learn-more--btn,
  .IYP-order-trowit--btn {
    align-self: center;
    margin-top: 15rem;
    font-size: 3rem;
    justify-self: center;
    margin: 0;
  }
  .IYP-order-trowit--btn {
    grid-column: 5/11;
    grid-row: 3/4;
    justify-self: center;
    align-self: flex-start;
    height: fit-content;
  }

  .IYP-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 11/13;
    margin-left: -3rem;
    grid-row: 3/4;
    justify-self: center;
    align-self: flex-start;
    height: fit-content;
    margin-top: 2rem;
  }

  .IYP--main-img {
    margin-left: -20rem;
    min-width: 80rem;
    /* margin-right: 10rem; */
  }
}

@media screen and (min-width: 891px) and (max-width: 1135px) {
  #impress_your_peers {
    gap: 4rem;
  }

  .IYP-text--p1,
  .IYP-text--p2 {
    font-size: 3rem;
    grid-column: 5/13;
  }

  .IYP-order-trowit--btn {
    margin-left: 10rem;
    grid-column: 1/7;
    font-size: 2.8rem;
    grid-row: 3/4;
    justify-self: flex-start;
    align-self: flex-start;
    height: fit-content;
    margin: 3rem 2rem;
  }

  .IYP-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 7/9;
    font-size: 3rem;
    /* margin-left: -3rem; */
    margin-top: 3rem;
    grid-row: 3/4;
    justify-self: flex-start;
    align-self: center;
    height: fit-content;
    margin: 0;
    margin-left: -4rem;
  }

  .IYP--main-img {
    /* margin-left: -20rem; */
    min-width: 60rem;
    /* margin-right: 10rem; */
  }
}

@media screen and (min-width: 648px) and (max-width: 891px) {
  .IYP-text--p1,
  .IYP-text--p2 {
    font-size: 2.5rem;
    grid-column: 5/15;
  }

  .IYP-order-trowit--btn {
    /* margin-left: 10rem; */
    grid-column: 1/8;
    font-size: 2.5rem;
    grid-row: 3/4;
    justify-self: flex-start;
    align-self: center;
    height: fit-content;
  }

  .IYP-learn-more--btn {
    /* margin-left: -3rem; */
    grid-column: 8/12;
    font-size: 2.8rem;
    margin: 0;
    margin-top: 3rem;
    grid-row: 3/4;
    justify-self: flex-start;
    align-self: center;
    height: fit-content;
  }

  .IYP--main-img {
    min-width: 50rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 649px) {
  #impress_your_peers {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;

    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .IYP--main-img {
    display: none;
  }

  .IYP-text--p1,
  .IYP-text--p2 {
    font-size: 2.2rem;
    width: 90vw;
    text-align: center;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .IYP-text--p2 {
    margin-top: 3rem;
  }

  .IYP-learn-more--btn {
    margin-inline: auto;
    padding: 1rem;
    font-size: 2rem;
  }
  .IYP-order-trowit--btn {
    margin-inline: auto;
    padding: 1rem;
    font-size: 2rem;
    height: fit-content;
    width: fit-content;
    padding-inline: 3rem;
  }
}
