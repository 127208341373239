.information-button-base {
	width: 8rem;
	height: 8rem;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	margin: 1rem;
}

.information-button-base.active {
	backdrop-filter: brightness(80%);
	box-shadow: 0 0 1rem #00000030;
}

.information-button-base > svg {
	width: 3rem;
	height: 3rem;
}

.information-button-base > p {
	margin-top: 1rem;
	font-size: 1.5rem;
	text-align: center;
}

.information-button-base:hover {
	background-color: #00000010;
	box-shadow: 0 0 1rem #00000015;
	cursor: pointer;
}
